// 
// 
// Product review
// Config file for product review 
// 
// 

import React, { useRef, useEffect } from 'react';


// 
// product review inline
// 


function ProductReview() {
      const prInlineRating = useRef(null);

      useEffect(() => {

            // 
            // product review inline
            // 
            const productReviewInline = document.createElement('script');

            productReviewInline.async = true;
            productReviewInline.innerHTML = `window.__productReviewCallbackQueue = window.__productReviewCallbackQueue || [];
                                window.__productReviewCallbackQueue.push(function(ProductReview) {
                                    ProductReview.use('inline-rating', {
                                    "configurationId": "76c8270e-4562-3bc3-b467-a115d9df7b46",
                                    "identificationStrategy": "from-internal-entry-id"
                                    });
                                });`;

            prInlineRating.current.parentElement.appendChild(productReviewInline);

            // 
            // product review id
            // 
            const productReviewBrandId = document.createElement('script');

            productReviewBrandId.async = true;
            productReviewBrandId.innerHTML = `{ brandId: 'd0bc25dc-9df3-3a2a-837f-e385b0bf996c' }`;

            document.body.appendChild(productReviewBrandId);


            // 
            // product review script
            // 
            const productReviewSrc = document.createElement('script');

            productReviewSrc.async = true;
            productReviewSrc.src = 'https://cdn.productreview.com.au/assets/widgets/loader.js';

            document.body.appendChild(productReviewSrc);

        }, [prInlineRating]);

      return (
            <>
                  <div ref={prInlineRating} data-id="15c58c42-feaf-36a7-8e6a-48778d3667ca" className="pr-inline-rating d-flex align-items-center justify-content-center">&nbsp;</div> 
            </>
      )
}

export default ProductReview;