import React from 'react';
import Image from 'components/common/Image';
import ProductReview from 'components/common/ProductReview';
import LayoutPanel from 'components/layout/layoutPanel';

function AwardsInfo (props) {

    return (
        <>
            <LayoutPanel background={props.panelBackground}
                         padding={props.panelPadding}
                         id="awards-info">
                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-10 col-lg-8 text-center">
                            <h3 className="mer-panel__title">Award-winning customer service</h3>
                            <p className="mer-panel__description">We aim to make gas easy for all our customers and we’re thrilled they agree. Join WA's most satisfied customers at Australia's most trusted natural gas supplier.</p>
                        </div>

                        <div className="mer-panel__images col-10 col-md-10 col-lg-6 mt-0 pt-0">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-5 col-sm-3">
                                    <a href="https://www.canstarblue.com.au/awards/most-trusted/" 
                                       title="Click to view 2024 Most trusted award" 
                                       target="_blank"
                                       className="mer-link--hover-none">
                                        <Image className="mer-panel__image"
                                            src="/canstar-ng-24.png"
                                            alt="2024 Most trusted award"
                                        />
                                    </a>
                                </div>

                                <div className="col-5 col-sm-3">
                                    <a href="https://www.canstarblue.com.au/awards/most-trusted/" 
                                       title="Click to view 2023 Most trusted award" 
                                       target="_blank"
                                       className="mer-link--hover-none">
                                        <Image className="mer-panel__image"
                                            src="/canstar-ng-23.png"
                                            alt="2023 Most trusted award"
                                        />
                                    </a>
                                </div>

                                <div className="col-5 col-sm-3">
                                    <a href="https://www.canstarblue.com.au/gas/natural-gas-suppliers-wa/" 
                                       title="Click to view 2023 Most satisfied customers WA award" 
                                       target="_blank"
                                       className="mer-link--hover-none">
                                        <Image className="mer-panel__image"
                                            src="/canstar-ng-23-customers.png"
                                            alt="2023 Most satisfied customers WA award"
                                        />
                                    </a>
                                </div>

                                <div className="col-5 col-sm-3">
                                    <a href="https://www.canstarblue.com.au/gas/natural-gas-suppliers-wa/" 
                                       title="Open to view 2022 Most satisfied customers WA award" 
                                       target="_blank"
                                       className="mer-link--hover-none">
                                        <Image className="mer-panel__image"
                                            src="/canstar-ng-22.png"
                                            alt="2022 Most satisfied customers WA award"
                                        />
                                    </a>
                                </div>
                                
                                <div className="col-8 col-sm-5 col-lg-5 text-center">
                                    <Image className="mer-panel__image"
                                        src="/logo/productreview-2023-cropped.png"
                                        alt="Product review logo"
                                    />
                                    <ProductReview />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutPanel>
        </>
    );
}

export default AwardsInfo;