import React from 'react';
import Image from '../common/Image';
import LayoutPanel from '../layout/layoutPanel';

function ChooseKleenheat (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}
                     id="choose-kleenheat">
            <div className="container">

                <div className="row mer-panel justify-content-center">
                    <div className="col-12 col-sm-10 col-md-9 col-lg-8 mb-3">
                        <div className="content text-center">
                            <h2>Why choose Kleenheat?</h2>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-lg-between justify-content-center align-items-center mer-text--size-lg">
                    <div className="col-12 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6 col-xl-5 mer-panel__images">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-wa.svg"
                                    alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-8 text-lg-center">
                                <h5 className="my-0">WA born and bred</h5>
                                <p>We've been at home in WA for over <span className="mer-text--no-wrap">65 years</span>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6 col-xl-5 mer-panel__images">
                                <Image className="mer-panel__image"
                                    src="/logo/rac.png"
                                    alt="RAC logo"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-8 text-lg-center">
                                <h5 className="my-0">RAC members save more</h5>
                                <p>Save an extra 3% on your natural gas usage charges.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6 col-xl-5 mer-panel__images">
                                <Image className="mer-panel__image"
                                    src="/canstar-ng-24.png"
                                    alt="2024 Natural Gas Suppliers"
                                />
                            </div>
                            <div className="col-8 col-sm-4 col-lg-8 text-lg-center">
                                <h5 className="my-0">Award-winning service</h5>
                                <p>Our local team are here to make gas easy for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default ChooseKleenheat;