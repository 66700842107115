import React from "react";

import { LinkModal } from "kh-common-components";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/Seo";

import LayoutMain from "../components/layout/layoutMain";
import Faq from '../components/common/Faq';
import Link from '../components/common/Link';

import ChooseKleenheatInfo from '../components/cards/ChooseKleenheatInfo';
import FlybuysPlans from '../components/cards/FlybuysPlans';
import AwardsInfo from '../components/cards/AwardsInfo';

import flybuys1pt from "../images/flybuys/flybuys-1pt.png";

import { modalContents } from "../constants/modal-content";
import faqDataOffer from '../constants/components/accordion-list/flybuys';

import "styles/pages/flybuys.css";

function Flybuys() {

    return (
    <>
        <SEO title="Flybuys – Kleenheat" 
            description="Collect Flybuys points on your natural gas spend."
        />

        <Header />
        <LayoutMain>
        
        <section className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg">
            <div className="container">
                <div className="row mer-panel align-items-center justify-content-around">
                    <div className="col-10 col-sm-6 col-lg-7 offset-lg-1 order-2 order-sm-1 mer-text--size-lg">
                        <div className="content">
                            <h1>Collect Flybuys points on your natural gas spend.</h1>
                            <ul>
                                <li>Collect 1 Flybuys point per $1 spent on natural gas.<sup>~</sup></li>
                                <li>Plus, save up to 35% off your natural gas usage charges for 12 months.<sup>*</sup></li>
                                <li>No sign-up or exit fees, and no lock-in contracts.</li>
                            </ul>

                            <div>
                                <small>
                                    <LinkModal
                                        modalContents={modalContents}
                                        useModal="true"
                                        displayContent={<span><sup>*~</sup>Terms and conditions apply</span>}
                                        title="Terms and conditions"
                                        modalTitle="Terms and conditions"
                                        modalContentName="flybuys_dollar_spend_terms_conditions"
                                        okayButtonLabel="Okay"
                                    />
                                </small>
                            </div>

                            <div className="mer-panel__actions mer-button-group">
                                <Link text="Switch now"
                                    link="/sign-up"
                                    linkClass="mer-button mer-button--primary" />
                                
                                <Link text="View plans"
                                    link="#plans"
                                    linkClass="mer-button mer-button--secondary" />
                            </div>
                        </div>
                    </div>
                    <div className="mer-panel__images hero-banner-flybuys col-8 col-sm-6 col-lg-4 order-1 order-sm-2 mt-0">
                        <img src={flybuys1pt} 
                            className="mer-panel__image"
                            alt="Flybuys 1 point per dollar" />
                    </div>
                </div>
            </div>
        </section>

        <ChooseKleenheatInfo panelBackground="mer-bg--ui-light"
                        panelPadding="mer-pt-lg" />

        <FlybuysPlans />

        <AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    panelPadding="mer-py-de" />

        <Faq panelBackground="mer-bg--ui-light"
            panelPadding="mer-py-lg"
            id="faq"
            data={faqDataOffer}
        />

        <section id="sign-up" 
                    className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 col-lg-6">
                    <div className="content text-center">
                        <div className="mer-text--size-lg">
                            <h2>Sign up to Kleenheat today.</h2>
                            <p>Start collecting points on your natural gas spend. Sign up online in a few easy steps.</p>
                            <a href={`/sign-up`}
                                className="mer-button mer-button--primary mb-3">
                                <span className="mer-color--gs-white">Sign up</span>
                            </a>
                        </div>
                    </div>
                    </div>
                </div>                
            </div>
        </section>
        </LayoutMain>
        <Footer />
    </>
    );
}

export default Flybuys;
